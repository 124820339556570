$(document).on('turbolinks:load', function(){
  $(document).ready(function () {
    $('form').parsley();
  })
  
  window.Parsley.addValidator('fileextension', (function (value, requirement) {
    var allowedExtensions, fileExtension;
    fileExtension = value.split('.').pop();
    allowedExtensions = requirement.split(',');
    return $.inArray(fileExtension, allowedExtensions) > -1;
  }), 32).addMessage('en', 'fileextension', 'Please enter a valid file');

  window.Parsley.addValidator('filesize', (function (value, requirement) {
    var input = $('input[type="file"]');
    var maxsize = requirement;
    files = input[0].files;
    all_size = Object.values(files).map(a => a.size);
    for(let i = 0; i< all_size.length; i++) {
      if(maxsize * 1000 <= all_size[i]) {
         return false;
      }
    }
  }), 32).addMessage('en', 'filesize', 'Please upload under 1MB file');

  window.Parsley.addValidator('filecount', (function (value, requirement) {
    var input = $('input[type="file"]');
    max_count = requirement; 
    file_count = input[1].files.length;
    console.log(file_count <= max_count)
    return file_count <= max_count;
    var allowedExtensions, fileExtension;
  }), 32).addMessage('en', 'filesize', 'Please upload under 1MB file');

  window.Parsley.addValidator('filesizee', (function (value, requirement) {
    var input = $('input[type="file"]');
    var maxsize = requirement;
    files = input[1].files;
    all_size = Object.values(files).map(a => a.size);
    for(let i = 0; i< all_size.length; i++) {
      if(maxsize * 1000 <= all_size[i]) {
         return false;
      }
    }
  }), 32).addMessage('en', 'filesizee', 'Please upload under 1MB file');
    
  $('.sidebar-mini').resize()
  $.each($(".sidebar-dropdown > a"), function(ind, element){
    if($(element).parent().hasClass("active")) {
      $(element).next(".sidebar-submenu").slideDown(200);
      // $(element).parent().removeClass("active");
    }
  });

  $(document).on('click', 'li.menu', function(){
    if(!$(this).hasClass('sidebar-dropdown')){
      $(this).addClass('active');
      $('li').removeClass('active');
    }
  })

  $(".sidebar-dropdown > a").click(function() {
    $(".sidebar-submenu").slideUp(200);
    if($(this).parent().hasClass("active")) {
      $(this).parent().removeClass("active");
      $(".sidebar-dropdown").removeClass("active");
    }
    else{
      $(".sidebar-dropdown").removeClass("active");
      $(this).next(".sidebar-submenu").slideDown(200);
      $(this).parent().addClass("active");
    }
  });
  $('.sidebar-submenu > ul > li .active').closest('.sidebar-dropdown').addClass('active')
  $('.sidebar-submenu > ul > li .active').closest('.sidebar-submenu').slideDown(200);
  $('.sidebar-toggle').click(function(){
    if ($('body').hasClass('sidebar-collapse')){
      $('.user-profile').show()
      // Written Below line for stop scrolling when main side bar opens
      $('body').css('overflow','hidden');
    } else {
      $('.user-profile').hide()
      // Written Below line for enable scrolling when main side bar collapsed
      $('body').css('overflow','auto');
    }
  })

  $("body").click(function(e) {
    if (!$('body').hasClass('sidebar-collapse') && !e.target.classList[0].includes('sidebar-toggle') && !e.target.classList[0].includes('fa-bars') && e.target.classList[0].includes('sidenav-overlay')){
      $('.sidebar-mini').addClass('sidebar-collapse')
      $('body').css('overflow','auto');

    }
  })

  $(".wrapper").on("scroll load", function() {
    $(".search_bar").blur();
  })
});
